@import '../../../../styles/colors';
@import '../../../../styles/spacing';
@import '../../../../styles/breakpoints';

.item {
  margin: pixelToRem(15) pixelToRem(51) pixelToRem(30) $SPACE_LARGE;
  display: flex;
  flex-direction: row;
}

.root {
  background-color: $PRIMARY_01;
  height: pixelToRem(24);
}

.rect {
  width: pixelToRem(24);
  border-radius: 4px;
}

.text {
  margin-left: $SPACE_SMALLER;
  width: pixelToRem(163);
  transform: none;
}

@include tabletDown {
  .item {
    margin: pixelToRem(15) 0 pixelToRem(30) 0;
    justify-content: center;
  }

  .text {
    display: none;
  }
}
