@import '../../../../styles/spacing';
@import '../../../../styles/breakpoints';

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    a {
      color: rgba(255, 255, 255, 0.89);
      display: block;
      padding: pixelToRem(15) $SPACE_LARGE;
      transition: all 0.15s ease-in-out 0s;

      > svg {
        margin-right: $SPACE_MEDIUM;
      }

      &:hover {
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.89);
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.2);
        color: white;
        font-weight: 600;
      }
    }
  }
}

@include tabletDown {
  .nav li a {
    padding: pixelToRem(15) pixelToRem(17);
    text-align: center;

    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}
