@import "../../../styles/breakpoints";
@import "../../../styles/utils";
@import "../../../styles/variables";

.pageContentWrapper {
  padding-top: pixelToRem(101);
}

.appContainer {
  padding-left: pixelToRem($asideWidthTabletUp);
}

.lessPadding {
  padding-left: pixelToRem(80);
}

@include tabletDown {
  .appContainer {
    padding-left: pixelToRem($asideWidthTabletDown);
  }
}
