@import './utils.scss';

// https://gitlab.com/rentacenter/rac-pad/racstrap/-/blob/develop/src/styles/spacing.scss
$BASE_SPACING: pixelToRem(4);

$SPACE_TINY: $BASE_SPACING;
$SPACE_SMALLER: $BASE_SPACING * 2;
$SPACE_SMALL: $BASE_SPACING * 3;
$SPACE_MEDIUM: $BASE_SPACING * 4;
$SPACE_REGULAR: $BASE_SPACING * 5;
$SPACE_LARGE: $BASE_SPACING * 6;
$SPACE_LARGER: $BASE_SPACING * 8;
$SPACE_LARGEST: $BASE_SPACING * 10;
$SPACE_HUGE: $BASE_SPACING * 12;
$SPACE_MEGA: $BASE_SPACING * 14;
$SPACE_JUMBO: $BASE_SPACING * 16;
