@import "../../styles/utils";
@import "../../styles/breakpoints";

.dap {
  footer {
    left: pixelToRem(50);

    @include tabletUp {
      left: pixelToRem(270);
    }
  }
}