// https://gitlab.com/rentacenter/rac-pad/racstrap/-/blob/develop/src/styles/colors.scss
$PRIMARY_01: #0c7aab;

$red: #ff0000;
$grey1: #dbe0e5;
$grey2: #8e9ea5;
$grey3: #5b6c7a;
$grey4: #21404e;
$black: #13252e;
$blue: #08648c;
