@import "../../../styles/utils";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/breakpoints";

$titleFontSize: 30;

.header {
  background-color: white;
  padding-left: pixelToRem($asideWidthTabletUp);
  color: $grey3;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  min-height: pixelToRem($headerHeight);
  display: flex;
}

.lessPadding {
  padding-left: pixelToRem(64);
}

.left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: pixelToRem(20);
  padding-right: pixelToRem(5);
}

.title {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: pixelToRem($titleFontSize);
}

.right {
  display: flex;
  align-items: center;
}

.welcome {
  margin: 0;
  padding: 0;
}

.right .signOutBtn {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
    background-color: $grey1;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }
}

@include tabletDown {
  .header {
    padding-left: pixelToRem($asideWidthTabletDown);
  }

  .title {
    font-size: pixelToRem($titleFontSize - 10);
  }
}
