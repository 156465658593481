@import "./styles/colors.scss";

html {
  font-size: 16px;
}

body {
  line-height: 1.15;
  color: $black;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
  margin-bottom: initial;
}

button:focus {
  outline: initial;
}
