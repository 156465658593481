@import "../../../styles/utils.scss";
@import "../../../styles/colors.scss";

.wrapper {
  .btnLink {
    background-color: transparent;
    border: none;
    color: $grey4;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      border: none;
      color: $grey4;
      box-shadow: none;
      outline: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  .menu {
    padding: 0;
    border-color: $grey2;
  }
}
