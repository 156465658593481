@import '../../../styles/colors';
@import '../../../styles/utils';
@import '../../../styles/breakpoints';
@import '../../../styles/variables';

.aside {
  background-color: $blue;
  position: fixed;
  top: 0;
  bottom: 0;
  width: pixelToRem($asideWidth);
  z-index: 100;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: pixelToRem($headerHeight);
}

.header svg {
  width: pixelToRem($asideWidth - 20);
}

.title {
  display: none;
}

.footer {
  position: absolute;
  display: none;
  justify-content: flex-end;
  align-items: center;
  padding-right: pixelToRem(18);
  bottom: 0;
  height: pixelToRem(77);
  width: 100%;
  border-top: pixelToRem(1) solid #b3c0cb;

  .collapseIcon {
    cursor: pointer;
    font-size: pixelToRem(24);
  }
}

.collapsed {
  .aside {
    width: pixelToRem($asideWidthTabletDown);

    > a {
      padding-left: 0;
    }

    li a span {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: pixelToRem($headerHeight);
  }

  .title {
    display: none;
  }
}

@include tabletUp {
  .aside {
    width: pixelToRem($asideWidthTabletUp);
  }

  .footer {
    display: flex;
  }

  .header {
    justify-content: flex-start;
    padding-left: pixelToRem(23);
  }

  .title {
    display: block;
    font-size: pixelToRem(20);
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    padding-left: pixelToRem(5);
  }
}
